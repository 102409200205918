<template>
    <div class="article-page">
        <header-com :title="pageTitle" :hasBack="true" textAlign="left" />
        <div class="article-wrapper">
            <div class="title">{{ articleMsg.title }}</div>
            <div class="time">{{ formatTime(articleMsg.createTime) }}</div>
            <div class="content" v-html="articleMsg.content"></div>
        </div>
    </div>
</template>
<script>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import HeaderCom from '@/components/header/Header.vue';
import commonRequest from '@/services';
import { DATE_FORMATE } from '@/constants';
import dayjs from 'dayjs';
export default {
    name: 'NoticeDetail',
    components: {
        [HeaderCom.name]: HeaderCom,
    },
    setup() {
        const route = useRoute();
        console.log(route.query, 'route');

        const pageTitle = ref('公告');
        const articleMsg = ref({});
        commonRequest({ action: 11003, id: route.query.id }).then((res) => {
            if (res.data) {
                articleMsg.value = res.data;
            }
        });
        const formatTime = (time) => {
            return dayjs(time).format(DATE_FORMATE);
        };
        return {
            pageTitle,
            articleMsg,
            formatTime,
        };
    },
};
</script>
<style lang="less" scoped>
.article-wrapper {
    background: #fff;
    display: flex;
    flex-direction: column;
}
.article-wrapper {
    flex: 1;
    overflow: auto;
    box-sizing: border-box;
    padding: 23px 15px;
    .title {
        font-size: 18px;
        font-family: PingFang SC, serif;
        font-weight: bold;
        line-height: 25px;
        color: #333333;
        text-align: center;
    }
    .time {
        font-size: 12px;
        font-family: PingFang SC, serif;
        line-height: 17px;
        color: #999999;
        margin: 25px 0 14px 0;
    }
    .content {
        font-size: 12px;
        font-family: PingFang SC, serif;
        line-height: 20px;
        color: #999999;
        /*letter-spacing: 15px;*/
    }
    p {
        margin-bottom: 10px;
    }
}
</style>
